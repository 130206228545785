import api from "./apiService";

var errorData;


const apiCommoncall = (
    method,
    endpoint,
    callingFunctionName,
    data = null,
    callback
) => {
  var params = {
    method: method,
    withCredentials: true,
    credentials: "include", //allow cross domain cookie sharing
  };
 
  if (method === "post") {
    params.data = JSON.stringify(data);
  }
  console.log("request params " + endpoint, params);

  api(endpoint, params)
      .then((response) => {
        console.log(`Response ${endpoint} : `, response);

        if (response.data.hasOwnProperty("error")) {
          errorData = {
            msg: response.data.error,
            trace: [],
            component: callingFunctionName,
          };
          logError(errorData);
        }
        callback(response.data);
      })
      .catch((error) => {
        console.log("catch error=", error)
        if (callingFunctionName === "getHeaderConfigService") {
          console.log("url:" + window._env_.REACT_APP_LOGOUT_URL);
          //window.location.href = window._env_.REACT_APP_LOGOUT_URL;
        }
        errorData = {
          msg: error.message,
          trace: error.stack,
          component: callingFunctionName,
        };
         logError(errorData);
      });
};

const getHeaderConfigCall = (callback) => {
  apiCommoncall(
      "get",
      "/restapi/headerconfigs",
      "getHeaderConfigService",
      "",
      callback
  );
};

const getAuthorizationCall = (params, callback) => {
  console.log("param=", params);
  apiCommoncall(
      "post",
      "/restapi/director",
      "checkSessionValidService",
      params,
      callback
  );
};

const getReportConfigCall = (productId,callback) => {
  apiCommoncall(
      "get",
      "/restapi/reportconfigsbyproduct/productcode/"+productId,
      "getReportConfigService",
      "",
      callback
  );
};

const getDashboardConfigCall = (productId,callback) => {
  apiCommoncall(
      "get",
      "/restapi/dashboardconfigs/productcode/"+productId,
      "getDashboardConfigService",
      "",
      callback
  );
};

const getReportTokenCall = (productId,reportId, callback) => {
  apiCommoncall(
      "get",
      "/restapi/reporttoken/reportid/"+productId+"/" + reportId,
      "getReportTokenCallService",
      "",
      callback
  );
};

const addReportViewPrintActivityCall = (productId,reportId, event, callback) => {
  apiCommoncall(
      "get",
      "/restapi/logevent/event/" + event + "/reportid/"+productId +"/" + reportId,
      "addReportViewPrintActivityService",
      "",
      callback
  );
};

const getannouncement = (productId,callback) => {
  apiCommoncall("get", "/restapi/notice/productcode/"+productId, "getannouncement", "", callback);
};



const getBookmarkByReportCall = (data, callback) => {
  api("/restapi/getBookmarkByReport", {
    method: "post",
    withCredentials: true,
    credentials: "include",
    data: data,
  })
    .then((response) => {
      callback(response.data);
    })
    .catch((e) => {
      console.log(e);
    });
};

const UpdateAnnouncement = (data,productId, callback) => {
  api("/restapi/notice/productcode/"+productId, {
    method: "post",
    withCredentials: true,
    credentials: "include",
    data: data,
  })
    .then((response) => {
      callback(response.data);
    })
    .catch((e) => {
      console.log(e);
    });
};

const saveBookmarkCall = (data, callback) => {
  api("/restapi/saveBookmark", {
    method: "post",
    withCredentials: true,
    credentials: "include",
    data: data,
  })
    .then((response) => {
      callback(response.data);
    })
    .catch((e) => {
      console.log(e);
    });
};


const updateBookmarkCall = (data, callback) => {
  api("/restapi/updateBookmark", {
    method: "post",
    withCredentials: true,
    credentials: "include",
    data: data,
  })
    .then((response) => {
      callback(response.data);
    })
    .catch((e) => {
      console.log(e);
    });
};

const deleteBookmarkcall = (data, callback) => {
  api("/restapi/deleteBookmark", {
    method: "post",
    withCredentials: true,
    credentials: "include",
    data: data,
  })
    .then((response) => {
      callback(response.data);
    })
    .catch((e) => {
      console.log(e);
    });
};



const updateiqdpreport = (data, callback) => {
  console.log('window._env_.REACT_APP_API_BASE_URL',window._env_.REACT_APP_API_BASE_URL);
  api(window._env_.REACT_APP_API_BASE_URL + "/iqdprestapi/updatereport", {
    method: "post",
    withCredentials: true,
    credentials: "include",
    data: JSON.stringify(data),
  })
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

const createfolder = (data, callback) => {
  api(window._env_.REACT_APP_API_BASE_URL + "/iqdprestapi/createfolder", {
    method: "post",
    withCredentials: true,
    credentials: "include",
    data: JSON.stringify(data),
  })
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

const updatefolder = (data, callback) => {
  api(window._env_.REACT_APP_API_BASE_URL + "/iqdprestapi/updatefolder", {
    method: "post",
    withCredentials: true,
    credentials: "include",
    data: JSON.stringify(data),
  })
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

const copyReport = (data, callback) => {
  api(window._env_.REACT_APP_API_BASE_URL + "/iqdprestapi/copyreport", {
    method: "post",
    withCredentials: true,
    credentials: "include",
    data: JSON.stringify(data),
  })
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

const moveReport = (data, callback) => {
  api(window._env_.REACT_APP_API_BASE_URL + "/iqdprestapi/movereport", {
    method: "post",
    withCredentials: true,
    credentials: "include",
    data: JSON.stringify(data),
  })
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

const deleteReport = (data, callback) => {
  //apiCommonPostCall("/iqdprestapi/deletereport","deleteReport",data,callback);
  api(window._env_.REACT_APP_API_BASE_URL + "/iqdprestapi/deletereport", {
    method: "post",
    withCredentials: true,
    credentials: "include",
    data: JSON.stringify(data),
  })
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

const copyFolder = (data, callback) => {
  api(window._env_.REACT_APP_API_BASE_URL + "/iqdprestapi/copyfolder", {
    method: "post",
    withCredentials: true,
    credentials: "include",
    data: JSON.stringify(data),
  })
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

const moveFolder = (data, callback) => {
  api(window._env_.REACT_APP_API_BASE_URL + "/iqdprestapi/movefolder", {
    method: "post",
    withCredentials: true,
    credentials: "include",
    data: JSON.stringify(data),
  })
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

const deleteFolder = (data, callback) => {
  api(window._env_.REACT_APP_API_BASE_URL + "/iqdprestapi/deletefolder", {
    method: "post",
    withCredentials: true,
    credentials: "include",
    data: JSON.stringify(data),
  })
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

const bulk = (data, callback) => {
  api(window._env_.REACT_APP_API_BASE_URL + "/iqdprestapi/bulk", {
    method: "post",
    withCredentials: true,
    credentials: "include",
    data: JSON.stringify(data),
  })
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

const restoreReportOrFolder = (data, callback) => {
  api(window._env_.REACT_APP_API_BASE_URL + "/iqdprestapi/restorereport", {
    method: "post",
    withCredentials: true,
    credentials: "include",
    data: JSON.stringify(data),
  })
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

const perDeleteReportOrFolder = (data, callback) => {
  api(window._env_.REACT_APP_API_BASE_URL + "/iqdprestapi/permdeletereport", {
    method: "post",
    withCredentials: true,
    credentials: "include",
    data: JSON.stringify(data),
  })
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
}
const getFolderData = (callback) => {
  apiCommoncall( "get","/iqdprestapi/folderinfo", "getFolderData", "", callback);
};


const getReportData = (callback) => {
  apiCommoncall( "get","/iqdprestapi/reportinfo", "getReportData","", callback);
};

const getShowReportData = (reportId, callback) => {
  apiCommoncall("get","/iqdprestapi/iqdpreportinfo/reportid/" + reportId, "getShowReportData",'', callback);
};

const getIqdpReportTokenData = (reportId, callback) => {
  apiCommoncall("get","/iqdprestapi/iqdpreporttoken/reportid/" + reportId, "getShowReportData", '', callback);
};
const getIqdpDataSetInfo = (callback) => {
  apiCommoncall("get","/iqdprestapi/datasetsinfo", "getDatasetsInfo", '',callback);
}

const updatepbreportid = (reportId, pbReportId, callback) => {
  apiCommoncall("get","/iqdprestapi/updatepbreportid/reportid/" + reportId + "/pbreportid/" + pbReportId, "updatepbreportid",'', callback);
}


const updateModifiedDate = (data, callback) => {
  console.log('data',data);
  api(window._env_.REACT_APP_API_BASE_URL + "/iqdprestapi/updatesavetime", {
    method: "post",
    withCredentials: true,
    credentials: "include",
    data: JSON.stringify(data),
  })
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
  
};

const createiqdpreport = (data, callback) => {
  api(window._env_.REACT_APP_API_BASE_URL + "/iqdprestapi/createreport", {
    method: "post",
    withCredentials: true,
    credentials: "include",
    data: JSON.stringify(data),
  })
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};
const logoutCall = (reason, callback) => {
  let currentLogoutUrl = localStorage.getItem("currentLogoutUrl");
  let currentuser =  localStorage.getItem("logoutUser");
  let reasondata = reason && ['timeout', 'sessionnotfound', 'other'].includes(reason) ? reason : null;
  let data = {
    logouturl : currentLogoutUrl,
    reasondata : reasondata,
    currentuser: currentuser
  };
  api("/restapi/logout", {
    method: "post",
    withCredentials: true,
    credentials: "include",
    data:JSON.stringify(data),
  })
    .then((response) => {
      console.log('logout response',response);
      callback(response.data);

    })
    .catch((e) => {
      console.log(e);
    });
  localStorage.removeItem("currentLogoutUrl");  
  localStorage.removeItem("jwtAcessTrail");  
  

};

const logError = (data) => {
  api("/restapi/logservice", {
    method: "post",
    withCredentials: true,
    credentials: "include",
    data: JSON.stringify(data),
  })
    .then((response) => {
      console.log(response);  
    })
    .catch((e) => {
      console.log(e);
    });
};


/**
 * This function makes a POST request to the '/restapi/usersync' endpoint.
 * It sends the provided data as a JSON string in the request body.
 * 
 * @param {Object} data - The data to be sent in the request body.
 */
const userSyncCall = (data) => {
  api("/restapi/usersync", {
    method: "post",
    withCredentials: true,
    credentials: "include",
    data: JSON.stringify(data),
  })
    .then((response) => {
      console.log(response);  
    })
    .catch((e) => {
      console.log(e);
    });
};



const exportFunctions = {
  logError,
  apiCommoncall,
  getAuthorizationCall,
  getHeaderConfigCall,
  getReportConfigCall,
  getDashboardConfigCall,
  getReportTokenCall,
  addReportViewPrintActivityCall,
  getannouncement,
  UpdateAnnouncement,
  logoutCall,
  saveBookmarkCall,
  updateBookmarkCall,
  getBookmarkByReportCall,
  deleteBookmarkcall,
  userSyncCall,
  getFolderData,
  getReportData,
  getShowReportData,
  getIqdpReportTokenData,
  getIqdpDataSetInfo,
  createiqdpreport,
  updateiqdpreport,
  createfolder,
  updatefolder,
  copyReport,
  moveReport,
  deleteReport,
  copyFolder,
  moveFolder,
  deleteFolder,
  restoreReportOrFolder,
  perDeleteReportOrFolder,
  bulk,
  updatepbreportid,
  updateModifiedDate,
};
export default exportFunctions;
